import type { ReactNode } from 'react';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { SubText } from 'components/Toolkit/Inputs/SubText';
import { ErrorMessage } from 'components/Toolkit/Inputs/ErrorMessage';
import { HelpText } from 'components/Toolkit/Inputs/HelpText';
import { ThemeTypes } from 'types/Theme.namespace';
import { Spacer } from 'helpers/genericStyles';

type CheckboxVariant = 'TICK' | 'DASH';

export interface CheckboxProps {
  /** Label for the checkbox */
  label?: ReactNode | string;
  /** Checkbox is selected. */
  checked?: boolean;
  /** Additional text to aide in use */
  helpText?: string;
  /** Disable input */
  disabled?: boolean;
  /** ID for form input */
  id?: string;
  /** Name for form input */
  name?: string;
  /** Value for form input */
  value?: string;
  /** Enable error state for input */
  hasError?: boolean;
  /** Display an error message */
  errorMessage?: string;
  /** Callback when checkbox is toggled */
  onChange?(newChecked: boolean): void;
  /** Callback when checkbox is focussed */
  onFocus?(): void;
  /** Callback when focus is removed */
  onBlur?(): void;
  /** Checkbox style variant */
  checkboxVariant?: CheckboxVariant;
  /** Will use sub text */
  willUseSubText?: boolean;
  /** type for React Final Form */
  type?: string;
  /** Text color of label */
  labelTextColor?: ThemeTypes.Colors;
  /** If label text should be bold when checkbox is checked */
  boldWhenChecked?: boolean;
  /** class name for additional styling */
  /** For external styling */
  className?: string;
  /** Reverses the order of the label and checkbox */
  flip?: boolean;
  /** For targeting during testing */
  'data-testid'?: string;
}

const Span = styled.span<{
  focused: boolean;
  disabled: boolean;
  checked?: boolean;
  checkboxVariant: CheckboxVariant;
}>`
  flex: 0 0 auto;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  overflow: hidden;
  width: 24px;
  height: 24px;
  top: 0px;
  left: 0px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: 1px solid
    ${({ theme, focused }) => (focused ? theme.colors.BLUE : theme.colors.GREY)};
  transition: all 0.2s ease-out;
  transform: rotate(0deg) scale(1);
  opacity: 1;

  &:after {
    position: absolute;
    content: '';
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-width: 0 3px 3px 0;
    opacity: 1;
    transition: all 0.2s ease-out;
  }

  ${({ checkboxVariant }) => {
    switch (checkboxVariant) {
      case 'DASH':
        return `
        &:after {
          transform: rotate(90deg) scale(1);
          display: none;
          opacity: 1;
          left: 8px;
          top: 4px;
          width: 6px;
          height: 12px;
          border: solid #ffffff;
          border-width: 0 2px 0 0;
          background-color: transparent;
          border-radius: 0;
        }
        `;
      case 'TICK':
        return `
        &:after {
          transform: rotate(45deg) scale(1);
          display: none;
          opacity: 1;
          left: 8px;
          top: 4px;
          width: 6px;
          height: 12px;
          border: solid #ffffff;
          border-width: 0 2px 2px 0;
          background-color: transparent;
          border-radius: 0;
        }
        `;
      default:
        return `
        &:after {
          transform: rotate(45deg) scale(1);
          display: none;
          opacity: 1;
          left: 8px;
          top: 4px;
          width: 6px;
          height: 12px;
          border: solid #ffffff;
          border-width: 0 2px 2px 0;
          background-color: transparent;
          border-radius: 0;
        }     
        `;
    }
  }};

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${({ theme }) => theme.colors.BLUE};
      border: 1px solid ${({ theme }) => theme.colors.WHITE};

      &:after {
        display: block;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
      border: 1px solid ${({ theme }) => theme.colors.GREY_LIGHT};
    `}
`;

const Label = styled.label<{
  disabled: boolean;
  hasError: boolean;
  checked?: boolean;
  textColor: ThemeTypes.Colors;
  boldWhenChecked: boolean;
}>`
  ${({ theme }) => theme.fontSize.M16};
  display: flex;
  position: relative;
  min-height: 24px;
  cursor: pointer;
  color: ${({ theme, textColor }) => theme.colors[textColor]};

  ${({ theme, disabled }) =>
    disabled ? `color: ${theme.colors.GREY_LIGHT}; cursor: not-allowed;` : ''};
  ${({ theme, hasError }) =>
    hasError
      ? `
    color: ${theme.colors.RED_DARK};
    ${Span} {
      border: solid 1px ${theme.colors.RED_DARK}; 
    }
  `
      : ''}

  ${({ theme, checked, boldWhenChecked }) =>
    boldWhenChecked && checked && `font-weight: ${theme.fontWeight.bold};`}
`;

type TInput = { checkboxVariant: CheckboxVariant };
const Input = styled.input<TInput>`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

export const LabelText = styled.span<{
  includesLabelText: boolean;
  reversed: boolean;
}>`
  flex: 1 1 auto;

  ${({ reversed, includesLabelText, theme }) =>
    reversed
      ? css`
          order: -1;
          padding-right: ${includesLabelText
            ? theme.spacing.S12
            : theme.spacing.S8};
        `
      : css`
          padding-left: ${includesLabelText
            ? theme.spacing.S12
            : theme.spacing.S8};
        `}
`;

function Checkbox({
  label = '',
  checked,
  disabled = false,
  id,
  name,
  value,
  hasError,
  helpText,
  errorMessage,
  onChange,
  onFocus,
  onBlur,
  checkboxVariant = 'TICK',
  willUseSubText = true,
  type,
  labelTextColor = 'BLACK',
  boldWhenChecked = false,
  className,
  flip = false,
  'data-testid': dataTestId,
}: CheckboxProps) {
  const [focused, setFocused] = useState(false);
  const alwaysSetId = id ? id : name;

  const includesLabelText = Boolean(
    (typeof label === 'string' && label.length > 0) ||
      (typeof label === 'object' && label !== undefined),
  );

  function handleFocus() {
    setFocused(true);
    onFocus && onFocus();
  }

  function handleBlur() {
    setFocused(false);
    onBlur && onBlur();
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange && onChange(event.currentTarget.checked);
  }

  return (
    <div className={className}>
      <Label
        disabled={disabled}
        hasError={hasError || Boolean(errorMessage && errorMessage.length > 0)}
        htmlFor={alwaysSetId}
        textColor={labelTextColor}
        boldWhenChecked={boldWhenChecked}
      >
        <Input
          type={type ?? 'checkbox'}
          id={alwaysSetId}
          name={name}
          value={value}
          checked={checked}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          disabled={disabled}
          checkboxVariant={checkboxVariant}
          data-testid={dataTestId}
          aria-label={name}
        />
        <Span
          focused={focused}
          checked={checked}
          disabled={disabled}
          checkboxVariant={checkboxVariant}
        />
        <LabelText includesLabelText={includesLabelText} reversed={flip}>
          {label}
        </LabelText>
      </Label>
      {willUseSubText && (
        <>
          <Spacer marginBottom="S4" />
          <SubText>
            {errorMessage ? (
              <ErrorMessage text={errorMessage} />
            ) : (
              helpText && <HelpText text={helpText} />
            )}
          </SubText>
        </>
      )}
    </div>
  );
}

export { Checkbox };
